import React from 'react'
import {
  Flex,
  Text,
  Stack,
  VStack,
  Button,
  HStack,
  Divider,
  SimpleGrid,
} from '@chakra-ui/react'
import { useFilters } from 'providers/FilterProvider'
import { Followup } from 'data-models/followup'
import Preschool from 'data-models/preschool'
import { Area } from 'data-models/area'
import { LanguageAnalysisType } from './LanguageAnalysisType'
import CustomSelect from 'common/CustomSelect'
import { useUser } from 'providers/UserProvider'
import { Department, UserRole } from 'data-models'
import { useFollowups } from 'services/followupService'
import { calculateAcademicYear } from 'common/utils'
import { format, getDate } from 'date-fns'
import sv from 'date-fns/locale/sv'
import { capitalize } from 'lodash-es'
import { useAreas } from 'services/areaService'
import { usePreschoolList } from 'services/preschoolService'
import { SelectOption } from 'data-models'
import { useTranslation } from 'react-i18next'
import { useDepartments } from 'services/departmentService'

const customLabelGenerator = (data: Partial<Followup>) => {
  const academicYear = calculateAcademicYear(
    new Date(data.endDate || new Date()),
  )
  const startDate = new Date(data.startDate || new Date())
  const endDate = new Date(data.endDate || new Date())

  const startDateDay = getDate(startDate)
  const endDateDay = getDate(endDate)

  const startMonthName = capitalize(
    format(startDate, 'LLLL', {
      locale: sv,
    }),
  )
  const endMonthName = capitalize(
    format(endDate, 'LLLL', {
      locale: sv,
    }),
  )

  return (
    <VStack w='full'>
      <Flex w='full' justify='start' fontSize='sm' fontWeight='bold'>
        {data.name}
      </Flex>
      <HStack w='full' fontSize='xs'>
        <Text>{academicYear}</Text>

        <Divider
          minH='3'
          height='full'
          borderColor='black?'
          orientation='vertical'
        />

        <Text>
          {startMonthName} {startDateDay}
        </Text>
        <Text>
          {` - `}
          {startMonthName === endMonthName ? '' : endMonthName} {endDateDay}
        </Text>
      </HStack>
    </VStack>
  )
}

interface LanguageAnalysisFilterProps {
  onFilterChange: (newFilter: Partial<LanguageAnalysisType>) => void
  isUpdateLoading: boolean
  resetStatistics: () => void
  filter: LanguageAnalysisType
}

export const LanguageAnalysisFilter = ({
  filter,
  isUpdateLoading,
  onFilterChange,
  resetStatistics,
}: LanguageAnalysisFilterProps) => {
  const { user } = useUser()
  const {
    response: { data: followups },
  } = useFollowups({ organizationId: user.organizationId })
  const { t } = useTranslation()
  const { currentFollowupId, setCurrentFollowupId } = useFilters()

  const { response: areaOptions } = useAreas({
    organizationId: user.organizationId,
  })

  const hasDepartments = sessionStorage.getItem('hasDepartments');
  const { response: departmentOptions } = useDepartments({
    organizationId: user.organizationId,
  })
  const {
    response: { preschools: preschoolOptions },
  } = usePreschoolList({ organizationId: user.organizationId })



  // const filteredFollowups = followups.filter(
  //   followup => followup.status !== 'Draft',
  // )

  
  

  const specifiedTaskIds = [
    '7ff9f536-d816-4a3c-af57-ff63b1ae97c0',
    '632fc3d0-9289-4a59-a7f3-d2c2ae42cce4'
  ];
  
  const filteredFollowups = followups.filter(followup => {
    return followup.status !== 'Draft' && followup.tasks.some((task:any) => specifiedTaskIds.includes(task.id));
  });

  
  const filteredPreschoolOptions =
    filter.areaIds.length > 0
      ? preschoolOptions.filter(preschool =>
          filter.areaIds.includes(preschool.areaId || ''),
        )
      : preschoolOptions

  const finalFilteredPreschoolOptions = filteredPreschoolOptions.map(
    preschool => {
      return {
        label: preschool.preschoolName || '',
        value: preschool.preschoolId || '',
      }
    },
  )

  function resetFilter() {
    onFilterChange({
      areaIds: [],
      followupId: currentFollowupId,
      preschoolIds: [],
      departmentIds: [],
    })
  }

  function onFollowupChange(selectedFollowup: Followup) {
    setCurrentFollowupId(selectedFollowup.id)
    onFilterChange({
      followupId: selectedFollowup.id,
      areaIds: [],
      preschoolIds: [],
    })
  }

  const onAreaChange = (areas?: Area[]) => {
    const areaIds = areas ? areas?.map(area => area.id) : []
    const preschools = preschoolOptions.filter(preschool =>
      filter.preschoolIds.includes(preschool.id || ''),
    )
    const preschoolIdsBelongingToAreas = preschools
      .filter(preschool => areaIds.includes(preschool.areaId || ''))
      .map(preschool => preschool.id!)

    onFilterChange({
      areaIds,
      preschoolIds: preschoolIdsBelongingToAreas,
    })
  }

  const onDepartmentChange = (departments?: Department[]) => {
    const departmentIds = departments ? departments?.map(departments => departments.id) : []
    // const preschools = preschoolOptions.filter(preschool =>
    //   filter.preschoolIds.includes(preschool.id || ''),
    // )
    // const preschoolIdsBelongingToDepartments = preschools
    //   .filter(preschool => departmentIds.includes(preschool.departmentId || ''))
    //   .map(preschool => preschool.id!)

    onFilterChange({
      departmentIds,
      // preschoolIds: preschoolIdsBelongingToDepartments,
    })
  }

  const onPreschoolChange = (options?: SelectOption[]) => {
    onFilterChange({
      preschoolIds: options ? options.map(option => option.value) : [],
    })
  }


  return (
    <Stack
      paddingTop='8'
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      className='card'
      id='filter'
    >
      <HStack justifyContent={'space-between'} w='full'>
        <HStack spacing='7'>
          <Stack direction='row' alignItems='center' spacing='3'>
            <SimpleGrid columns={1} row={2} gap={0}>
              <Text
                width={'100%'}
                height={'20px'}
                fontSize='12px'
                color='#5A738B'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
                overflow='hidden'
              >
                {t('FollowupFilter_FollowUp_Name')}
              </Text>
              <CustomSelect
                name='followups'
                value={
                  filteredFollowups.length > 0
                    ? filteredFollowups.find(
                        followup =>
                          followup.status !== 'Draft' &&
                          followup.id === filter.followupId,
                      ) || filteredFollowups[0]
                    : null
                }
                options={filteredFollowups}
                onChange={onFollowupChange}
                className='bold-value'
                searchable={true}
                isMulti={false}
                placeholder={t('ResultFilter_Choose_FollowUp')}
                minWidth='250px'
                maxWidth='250px'
                maxHeight='38px'
                customOptionGenerator={data => customLabelGenerator(data)}
              />
            </SimpleGrid>
          </Stack>
          <Stack direction='row' alignItems='center' spacing='3'>
            <SimpleGrid columns={1} row={2} gap={0}>
              <Text
                width={'100%'}
                height={'20px'}
                fontSize='12px'
                color='#5A738B'
              >
                {t('ResultFilter_Area')}
              </Text>
              <CustomSelect
                name='areas'
                value={areaOptions.filter(area =>
                  filter.areaIds.includes(area.id),
                )}
                options={areaOptions}
                onChange={onAreaChange}
                searchable={true}
                isMulti={true}
                minWidth='250px'
                placeholder={t('ResultFilter_Choose_Area')}
              />
            </SimpleGrid>
          </Stack>
          <Stack direction='row' alignItems='center' spacing='3'>
            <SimpleGrid columns={1} row={2} gap={0}>
              <Text
                width={'100%'}
                height={'20px'}
                fontSize='12px'
                color='#5A738B'
              >
                {t('ResultFilter_Preschool')}{' '}
              </Text>
              <CustomSelect
                name='preschools'
                value={finalFilteredPreschoolOptions.filter(preschool =>
                  filter.preschoolIds.includes(preschool.value),
                )}
                options={finalFilteredPreschoolOptions}
                onChange={onPreschoolChange}
                searchable={true}
                isMulti={true}
                minWidth='250px'
                placeholder={t('ResultFilter_Choose_Preschool')}
              />
             
            </SimpleGrid>
          </Stack>
          {
            hasDepartments === 'true' &&
          <Stack direction='row' alignItems='center' spacing='3'>
            <SimpleGrid columns={1} row={2} gap={0}>
              <Text
                width={'100%'}
                height={'20px'}
                fontSize='12px'
                color='#5A738B'
              >
                {t('ResultFilter_Department')}
              </Text>
              <CustomSelect
                name='departments'
                value={departmentOptions.filter(department =>
                  filter.departmentIds.includes(department.id),
                )}
                options={departmentOptions}
                onChange={onDepartmentChange}
                searchable={true}
                isMulti={true}
                minWidth='250px'
                placeholder={t('ResultFilter_Choose_Department')}
              />
            </SimpleGrid>
          </Stack>
}
          <SimpleGrid columns={1} row={2} gap={0}>
            <Text width={'100%'} height={'20px'} fontSize='12px'></Text>
            <Button variant='ghost' onClick={resetFilter}>
              {t('ResultFilter_Reset')}
            </Button>
          </SimpleGrid>
        </HStack>

        {user.role === UserRole.SUPERADMIN && (
          <SimpleGrid columns={1} row={2} gap={0} mr='auto'>
            <Text width={'100%'} height={'20px'} fontSize='12px'></Text>
            <Button
              isLoading={isUpdateLoading}
              size={'md'}
              h='10'
              className='hello'
              w='110px'
              fontSize={'md'}
              onClick={resetStatistics}
            >
              {t('Dashboardfilter_Update')}
            </Button>
          </SimpleGrid>
        )}
      </HStack>
    </Stack>
  )
}
