import React from 'react'
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  ReferenceLine,
} from 'recharts'
import { HStack, VStack, Text, useDisclosure, Stack } from '@chakra-ui/react'
import { ChartContainer, LegendItemRenderer } from 'common/Chart'
import {
  IndicatorColors,
  IndicatorLabels,
  IndicatorLabelsEn,
  IndicatorLabelsDe
} from './../Dashboard/Graphs/utils'
import { useState } from 'react'
import { useProgression } from './useProgression'
import {
  ProgressionFollowupColors,
  StatisticsCategories,
  StatisticsCategoriesEn,
  StatisticsCategoriesDe
} from 'data-models'
import { mean, sortBy } from 'lodash'
import { ProgressionFilterType } from './types'
import { Followups } from 'data-models/progression'
import { useTranslation } from 'react-i18next'
import ChartHelpButtonComponent from 'common/ChartDetailHelp/ChartHelpButtonComponent'
import ChartHelpComponent from 'common/ChartDetailHelp/ChartHelpComponent'

interface ProgressionOverTimeChartProps {
  filter: ProgressionFilterType,
  helpDetailText: string|undefined
}

export const ProgressionOverTime = ({
  filter,
  helpDetailText
}: ProgressionOverTimeChartProps) => {
  const { stats, loading } = useProgression(filter)

  const { isOpen, onToggle } = useDisclosure()

  const data: Followups[] = sortBy(
    stats.followups?.map(followup => {
      return {
        ...followup,
        averagePercent: (followup.averagePercent as number).toFixed(2),
      }
    }),
    'startDate',
  )

  const bars = ProgressionFollowupColors

  const [tooltip, setTooltip] = useState<any>('')
  const { t, i18n } = useTranslation()

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      for (const bar of payload)
        if (bar.dataKey === tooltip)
          return (
            <VStack
              bg='#344352'
              p='3'
              borderRadius='md'
              alignItems='flex-start'
              color='white'
            >
              <Text fontWeight='semibold'>{label}</Text>
              <HStack key={payload[0].name}>
                <Text fontWeight='semibold'>
                  {i18n.language === 'se'
                    ? StatisticsCategories.averagePercent
                    : i18n.language === 'de'
                    ? StatisticsCategoriesDe.averagePercent
                    : StatisticsCategoriesEn.averagePercent}
                  :{' '}
                </Text>
                <Text>{Math.round(bar.value)}%</Text>
              </HStack>
            </VStack>
          )
      return null
    }
    return null
  }

  const linepoints = [
    {
      x: 0,
      y: mean(stats.followups?.map(followup => followup.averagePercent)),
    },
  ]

  const meanValue = () =>
    mean(stats.followups?.map(followup => followup.averagePercent))

  const helptext =
    i18n.language === 'se'
      ? `Diagrammet visar förändringar i resultat över en viss tidsperiod. Med resultat menas andel poäng av maximalt antal poäng.\n Data kan jämföras med det genomsnittliga resultatet inom hela organisationen.`
      : i18n.language === 'de'
      ? 'Das Diagramm zeigt Änderungen der Ergebnisse über einen bestimmten Zeitraum. Mit Ergebnis ist der Prozentsatz der Punkte aus der maximalen Anzahl von Punkten gemeint.\n Die Daten können mit dem Durchschnittsergebnis innerhalb der gesamten Organisation verglichen werden.'
      : `The chart shows changes in results over a certain period of time. By result is meant the percentage of points out of the maximum number of points.\n The data can be compared with the average result within the entire organization.`

  return (
    <ChartContainer
      title={t('Progression_Over_Time')}
      helptext={helptext}
      loading={loading}
    >
      {data.length > 0 && (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Text
            w={{ base: '100%', md: '80%' }}
            style={{
              whiteSpace: 'pre-line',
              lineHeight: '2',
            }}
          >
            {data.length > 0 ? helptext : null}
          </Text>
          <ChartHelpButtonComponent onToggle={onToggle} isOpen={isOpen} />
        </Stack>
      )}
      {helpDetailText && (
        <ChartHelpComponent helpDetailText={helpDetailText} isOpen={isOpen} />
      )}
      <br></br>
      {data?.length > 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ResponsiveContainer width='80%' height={450}>
            <ComposedChart
              data={data}
              barGap='2%'
              barCategoryGap='15%'
              margin={{ bottom: 90, top: 10 }}
            >
              <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
              <YAxis
                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                unit='%'
                fontSize={12}
              >
                <Label
                  dx={-25}
                  angle={-90}
                  fontSize={'12px'}
                  value={`${t('Statistic_Results')} ${t('in')} %`}
                  fontWeight='bold'
                  style={{
                    textAnchor: 'middle',
                    width: '100%',
                  }}
                />
              </YAxis>
              <XAxis dataKey='name' interval={0} tickMargin={20} fontSize={12}>
                <Label
                  value={t('MainHeader_FollowUp')}
                  position='bottom'
                  fontWeight='bold'
                  offset={40}
                  fontSize={12}
                />
              </XAxis>
              <Tooltip
                formatter={value => `${value}%`}
                content={CustomTooltip}
                cursor={{ fill: '#277aff', fillOpacity: '0.1' }}
              />
              <Legend
                iconSize={10}
                formatter={LegendItemRenderer}
                verticalAlign='bottom'
                wrapperStyle={{ bottom: '20px', marginLeft: '40px' }}
              />
              <Bar
                dataKey={'averagePercent'}
                fill='#EC2215'
                name={
                  i18n.language === 'se'
                  ? StatisticsCategories.averagePercentForOrg
                  : i18n.language === 'de'
                  ? StatisticsCategoriesDe.averagePercentForOrg
                   : StatisticsCategoriesEn.averagePercentForOrg
                }
                barSize={80}
                isAnimationActive={true}
                onMouseOver={() => {
                  setTooltip('averagePercent')
                }}
              >
                {bars.map((entry, index) => (
                  <Cell fill={entry} key={`cell-${index}`} />
                ))}
              </Bar>
              <ReferenceLine
                y={meanValue()}
                stroke='#EC2215'
                strokeDasharray='3 3'
                segment={linepoints}
                strokeWidth={2}
                ifOverflow='extendDomain'
              />
              <Line
                name={
                  i18n.language === 'se'
                    ? IndicatorLabels.Progression
                    : i18n.language === 'de'
                    ? IndicatorLabelsDe.Progression
                    : IndicatorLabelsEn.Progression
                }
                dataKey={'averagePercent'}
                dot={{ stroke: IndicatorColors.Progression, strokeWidth: 6 }}
                stroke={IndicatorColors.Progression}
                strokeWidth={3}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className='nodata-avaliable'>{t('ResultsTable_No_Data')}</div>
      )}
    </ChartContainer>
  )
}
