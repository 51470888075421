import React from 'react'
import {
  Bar,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  ReferenceLine,
} from 'recharts'
import {
  HStack,
  VStack,
  Text,
  useRadioGroup,
  Grid,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react'
import { ChartContainer, LegendItemRenderer } from 'common/Chart'
import { useState } from 'react'
import { useProgression } from './useProgression'
import {
  IndicatorColorsConcated,
  ProgressionFollowupColors,
  StatisticsCategories,
  StatisticsCategoriesEn,
  StatisticsCategoriesDe
} from 'data-models'
import { isEqual, mean, sortBy, spread, union, uniqBy } from 'lodash'
import { ProgressionFilterType } from './types'
import { CustomRadioButton } from 'common/Feedback/components/CustomRadioButtonWithCheckmark'
import { FormWrapper } from 'common/Form'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import CheckIcon from 'pages/MainTabs/OverviewTab/TaskPreview/CheckIcon'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  ProgressionTypeLabel,
  ProgressionTypeLabelEn,
  ProgressionTypeLabelDe
} from 'data-models/progression'
import {
  generateChartBarName,
  generateChartBarNameProgressionChart,
} from '../Dashboard/Graphs/utils'
import { useTaskService } from 'services/taskService'
import { useTranslation } from 'react-i18next'
import CustomizedAxisTick from './CustomizedAxisTick'
import ChartHelpButtonComponent from 'common/ChartDetailHelp/ChartHelpButtonComponent'
import ChartHelpComponent from 'common/ChartDetailHelp/ChartHelpComponent'
interface ProgressionOverTimeChartProps {
  filter: ProgressionFilterType
  helpDetailText: string|undefined
}

export const progressionSchema = yup.object().shape({
  type: yup.string().required('Välj en typ'),
  value: yup.string().required('Välj en progressionstyp'),
})

export const ProgressionTaskLevel = ({
  filter,
  helpDetailText
}: ProgressionOverTimeChartProps) => {
  const { stats, loading } = useProgression(filter)
  const { isOpen, onToggle } = useDisclosure()
  const defaultValues: any = {
    value: '',
    type: ProgressionTypeLabel.Uppföljning,
  }

  const formMethods = useForm<any>({
    defaultValues,
    resolver: yupResolver(progressionSchema),
  })
  const { control, getValues } = formMethods
  const allFollowups = stats?.tasks?.map(task => task.followups)
  const allTasks = stats?.followups?.map(followup => followup.tasks)
  const combineFollowups = spread(union)
  const uniqueFollowups: any = combineFollowups(allFollowups)
  const barColors = ProgressionFollowupColors
  const forColors = uniqBy(uniqueFollowups, 'name').map((followup, index) => {
    return { [followup['name']]: barColors[index] }
  })
  const { tasks: realUnmutatedTasks } = useTaskService()

  const taskBars = ((uniqueFollowups?.length && uniqueFollowups) || [])?.map(
    category => ({
      key: `${category.id}_${category.name}`,
      name: category.name,
      color: forColors?.find(item =>
        Object.keys(item).includes(`${category.name}`),
      )![`${category.name}`],
    }),
  )
  const tasksData = stats.tasks?.map(stat => {
    const { followups } = stat
    const filteredStats = followups!.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr.id}_${curr.name}`]: (curr.averagePercent as number)?.toFixed(
          2,
        ),
      }),
      {},
    )

    const task = realUnmutatedTasks.find(task => task.id === stat.taskId)
    const taskName = task?.name || ''
    const taskDescription = task?.description || ''

    return {
      ...filteredStats,
      name: generateChartBarNameProgressionChart({
        categoryId: stat.categoryId,
        name: taskName + '<br>' + ' (' + taskDescription + ') ',
      }),
      average: stat.averagePercent?.toFixed(2),
    }
  })

  const combineTasks = spread(union)
  //these are not unique
  const uniqueTasks: any = combineTasks(allTasks)
  //these are
  const actualUniqueTasks = uniqBy(
    uniqueTasks.map(task => ({
      taskId: task.taskId,
      categoryId: task.categoryId,
      taskName: task.taskName,
    })),
    (entry: any) => `${entry.taskId}_${entry.categoryId + '' + entry.taskName}`,
  )

  const tasks = ((actualUniqueTasks?.length && actualUniqueTasks) || [])?.map(
    (category: any, index) => ({
      key: `${category.taskId}_${category.categoryId}`,
      name: generateChartBarName({
        categoryId: category.categoryId,
        name: (realUnmutatedTasks.find(task => task.id === category.taskId)
          ?.name +
          ' (' +
          realUnmutatedTasks.find(task => task.id === category.taskId)
            ?.description +
          ') ') as string,
      }),
      color: IndicatorColorsConcated[index],
    }),
  )

  const followupBars = tasks

  //stop block

  const followupData = sortBy(
    stats.followups?.map(stat => {
      const { tasks } = stat
      const stats = tasks.reduce(
        (acc, curr) => ({
          ...acc,
          [`${curr.taskId}_${curr.categoryId}`]: (
            curr.averagePercent as number
          )?.toFixed(2),
        }),
        {},
      )

      return {
        ...stats,
        name: stat.name,
        startDate: stat.startDate,
        average: (stat.averagePercent as number)?.toFixed(2),
      }
    }),
    'startDate',
  )

  const [tooltip, setTooltip] = useState<any>('')

  const CustomTooltipTasks = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      for (const bar of payload)
        if (bar.dataKey === tooltip)
          return (
            <VStack
              p='3'
              color='white'
              bg='#344352'
              borderRadius='md'
              alignItems='flex-start'
            >
              <Text fontWeight='semibold'>{label.split('<br>')}</Text>
              <HStack key={bar.name}>
                <Text fontWeight='semibold'>{bar.name}:</Text>
                <Text>{Math.round(bar.value)}%</Text>
              </HStack>
              <HStack key={payload[0].dataKey}>
                <Text fontWeight='semibold'>
                  {i18n.language === 'se'
                  ? StatisticsCategories.averagePercent
                  : i18n.language === 'de'
                  ? StatisticsCategoriesDe.averagePercent
                  :StatisticsCategoriesEn.averagePercent}
                  :{' '}
                </Text>
                <Text>{Math.round(payload[0].payload.average)}%</Text>
              </HStack>
            </VStack>
          )
      return null
    }
    return null
  }

  const CustomTooltipFollowup = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      for (const bar of payload)
        if (bar.dataKey === tooltip)
          return (
            <VStack
              bg='#344352'
              p='3'
              borderRadius='md'
              alignItems='flex-start'
              color='white'
            >
              <Text fontWeight='semibold'>{label}</Text>
              <HStack key={bar.dataKey}>
                <Text fontWeight='semibold'>{bar.name}: </Text>
                <Text>{Math.round(bar.value)}%</Text>
              </HStack>
              <HStack key={payload[0].name}>
                <Text fontWeight='semibold'>
                  {i18n.language === 'se'
                    ? StatisticsCategories.averagePercent
                    : i18n.language === 'de'
                    ? StatisticsCategoriesDe.averagePercent
                    : StatisticsCategoriesEn.averagePercent}
                  :{' '}
                </Text>
                <Text>{Math.round(payload[0].payload.average)}%</Text>
              </HStack>
            </VStack>
          )
      return null
    }
    return null
  }

  const linepointsTasks = [
    {
      x: 0,
      y: mean(stats.tasks?.map(task => task.averagePercent)),
    },
  ]
  const linepointsFollowups = [
    {
      x: 0,
      y: mean(followupData?.map(followup => followup.average)),
    },
  ]

  const meanValue = (ChartName: string) =>
    ChartName === 'tasks'
      ? mean(stats.tasks?.map(task => task.averagePercent))
      : mean(followupData?.map(followup => Number(followup.average)))
  const { t, i18n } = useTranslation()
  const options = [
    ProgressionTypeLabel.Uppföljning,
    ProgressionTypeLabel.Uppgift,
  ]

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'type',
    defaultValue: ProgressionTypeLabel.Uppföljning,
  })

  const group = getRootProps()

  const helptext =
    i18n.language === 'se'
    ? `Diagrammet visar progressionen uppdelad per uppgift och uppföljning.\n Diagrammet visar resultaten på två olika sätt, med utgångspunkt från uppföljningen eller med utgångspunkt från uppgifterna.\n Du kan göra ditt val genom att klicka i alternativen på höger sidan av diagrammet.`
    : i18n.language === 'de'
    ? `Das Diagramm zeigt den Fortschritt, aufgeschlüsselt nach Aufgabe und Nachverfolgung.\n Das Diagramm zeigt die Ergebnisse auf zwei verschiedene Arten, basierend auf der Nachverfolgung oder basierend auf den Aufgaben.\n Sie können Ihre Auswahl treffen, indem Sie auf die Optionen auf der rechten Seite des Diagramms klicken.`
    : `The chart shows the progression broken down by task and follow-up.\n The chart shows the results in two different ways, based on the follow-up or based on the tasks.\n You can make your choice by clicking on the options on the right side of the chart.`

  return (
    <ChartContainer
      title={t('Task_level_progression')}
      helptext={helptext}
      loading={loading}
    >
      <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
        <GridItem colSpan={{base:12,lg:8}} >
          <h3
            style={{
              whiteSpace: 'pre-line',
              lineHeight: '2',
            }}
          >
            {tasksData?.length > 0 ? helptext : null}
          </h3>
        </GridItem>
        <GridItem colSpan={{base:12,lg:2}} >
          {tasksData?.length && (
            <FormProvider {...formMethods}>
              <FormWrapper name={'type'} label={''}>
                <Controller
                  name='type'
                  control={control}
                  render={({ field }) => (
                    <HStack {...group} {...field}>
                      {options.map(value => {
                        const radio = getRadioProps({
                          value,
                          enterKeyHint: value,
                        })
                        return (
                          <CustomRadioButton
                            label={
                              i18n.language === 'se'
                                ? ProgressionTypeLabel[value]
                                : i18n.language === 'de'
                                ? ProgressionTypeLabelDe[value]
                                : ProgressionTypeLabelEn[value]
                            }
                            key={value}
                            {...radio}
                          >
                            <CheckIcon size={'16'} color='white' />
                          </CustomRadioButton>
                        )
                      })}
                    </HStack>
                  )}
                />
              </FormWrapper>
            </FormProvider>
          )}
        </GridItem>
       {tasksData?.length >0 &&
       <GridItem colSpan={{base:12,lg:2}} mr={{base:0,md:"5%"}} style={{ display: 'flex', alignItems: 'center',justifyContent:"space-between"}}>
       <ChartHelpButtonComponent onToggle={onToggle} isOpen={isOpen} />
       </GridItem>
       } 
      </Grid>
      {helpDetailText && <ChartHelpComponent helpDetailText={helpDetailText} isOpen={isOpen}/>}
      <br></br>
      {tasksData?.length && !isEqual(getValues(), defaultValues) ? (
        <div style={{display:'flex', justifyContent: 'center'}}>
        <ResponsiveContainer width='80%' height={500}>
          <BarChart data={tasksData} margin={{ bottom: 200, top: 10 }}>
            <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
            <YAxis
              ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              unit='%'
              fontSize={12}
            >
              <Label
                dx={-25}
                angle={-90}
                fontSize={'12px'}
                value={`${t('Statistic_Results')} ${t('in')}  %`}
                fontWeight='bold'
                style={{
                  textAnchor: 'middle',
                  width: '100%',
                }}
              />
            </YAxis>
            <XAxis
              dataKey='name'
              tickLine={false}
              angle={5}
              dy={5}
              interval={0}
              fontSize={12}
              textAnchor='start'
              padding={{ right: 0 }}
              tick={<CustomizedAxisTick />}
            >
              <Label
                value={t('Overview_Task')}
                position='bottom'
                fontWeight='bold'
                offset={150}
                fontSize={12}
              />
            </XAxis>
            <Tooltip
              formatter={value => `${value}%`}
              content={CustomTooltipTasks}
              cursor={{ fill: '#277aff', fillOpacity: '0.1' }}
            />
            <Legend
              iconSize={10}
              formatter={LegendItemRenderer}
              verticalAlign='bottom'
              payloadUniqBy
              wrapperStyle={{ bottom: '20px', marginLeft: '40px' }}
            />
            {taskBars.map(bar => (
              <Bar
                key={bar.key}
                dataKey={bar.key}
                barSize={120}
                name={bar.name}
                fill={bar.color}
                stroke={bar.color}
                isAnimationActive={true}
                onMouseOver={() => {
                  setTooltip(`${bar.key}`)
                }}
              />
            ))}
            <ReferenceLine
              y={meanValue('tasks')}
              stroke='#EC2215'
              strokeDasharray='3 3'
              segment={linepointsTasks}
              strokeWidth={2}
              ifOverflow='extendDomain'
            />
          </BarChart>
        </ResponsiveContainer>
        </div>
      ) : followupData?.length && isEqual(getValues(), defaultValues) ? (
        <div style={{display:'flex', justifyContent: 'center'}}>
        <ResponsiveContainer width='80%' height={500}>
          <BarChart
            data={followupData}
            barGap={140}
            barCategoryGap={60}
            margin={{ bottom: 120, top: 10 }}
          >
            <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
            <YAxis
              ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              unit='%'
              fontSize={12}
            >
              <Label
                dx={-25}
                angle={-90}
                fontSize={'12px'}
                value={`${t('Statistic_Results')} ${t('in')} %`}
                fontWeight='bold'
                style={{
                  textAnchor: 'middle',
                  width: '100%',
                }}
              />
            </YAxis>
            <XAxis dataKey='name' tickMargin={20} tickLine={false} dx={-30} fontSize={12}>
              <Label
                offset={50}
                value={t('MainHeader_FollowUp')}
                position='bottom'
                fontWeight='bold'
                fontSize={12}
              />
            </XAxis>
            <Tooltip
              formatter={value => `${value}%`}
              content={CustomTooltipFollowup}
              cursor={{ fill: '#277aff', fillOpacity: '0.1' }}
            />
            <Legend
              iconSize={10}
              formatter={LegendItemRenderer}
              verticalAlign='bottom'
              payloadUniqBy
              wrapperStyle={{ bottom: '20px', marginLeft: '40px' }}
            />
            {followupBars.map(bar => (
              <Bar
                key={bar.key}
                name={bar.name}
                fill={bar.color}
                dataKey={bar.key}
                stroke={bar.color}
                isAnimationActive={true}
                onMouseOver={() => {
                  setTooltip(`${bar.key}`)
                }}
              />
            ))}
            <ReferenceLine
              y={meanValue('followups')}
              stroke='#EC2215'
              strokeDasharray='3 3'
              segment={linepointsFollowups}
              strokeWidth={2}
              ifOverflow='extendDomain'
            />
          </BarChart>
        </ResponsiveContainer>
        </div>
      ) : (
        <div className='nodata-avaliable'>
          {t('ResultsTable_No_Data')}
        </div>
      )}
    </ChartContainer>
  )
}
